/*!
*
*  Global Styles for TLDs in BigCommerce
* ------------------------------------------------------------------------------
* 
*/
@import './fonts/gotham.css';
@import './fonts/gotham-condensed.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@import 'consent-manager';
}

@keyframes buttonLoader {
	25% {
		clip-path: polygon(0 0, 100% 0, 0 100%);
	}
	50% {
		clip-path: polygon(0 0, 100% 100%, 0 100%);
	}
	75% {
		clip-path: polygon(100% 0, 100% 100%, 0 100%);
	}
	100% {
		clip-path: polygon(100% 0, 100% 100%, 0 0);
	}
}

:root {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--bc-blue: theme('colors.bc-blue');
	--bc-green: theme('colors.bc-green');
	--bc-black: theme('colors.bc-black');
	--white: theme('colors.white');
}

div#__next {
	overflow: visible;
}

div#__next > div {
	max-width: 1681px;
}

.wistia_channel h1 {
	font-weight: 700 !important;
}

.custom-text ul {
	list-style: none;
	margin-left: 0 !important;
}

.custom-text ul > li {
	position: relative;
	margin-bottom: 0.75rem;
	list-style: none;

	&:last-child {
		margin-bottom: 0 !important;
	}
}

.custom-text ul > li:before {
	content: '◢';
	display: inline-block;
	font-size: 6px;
	font-style: normal;
	line-height: 1.5rem;
	position: absolute;
	left: -1.2rem;
	color: var(--list-marker-color);
}

.custom-text ol {
	counter-reset: item;
	list-style: none;
	margin-left: 0 !important;
	padding-left: 0 !important;
}

.custom-text ol > li {
	margin-bottom: 0.5rem;

	&:last-child {
		margin-bottom: 0 !important;
	}
}

.custom-text ol > li:before {
	content: counters(item, '.') '. ';
	counter-increment: item;
	color: var(--list-marker-color);
	margin-right: 0.25rem;
}

.custom-text a > span {
	font-weight: 500;
	transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.custom-text blockquote {
	border-left: 0;
	padding: 0;
	hanging-punctuation: first;
	text-indent: -0.5em;
}

// Search
#search-combobox {
	li {
		svg {
			color: var(--white);
		}

		&:hover {
			svg {
				color: var(--bc-blue);
			}
		}
	}
}

.mktoForm {
	width: 100% !important;
	padding: 0 !important;
	font-family: Gotham, Helvetica, Arial, sans-serif !important;

	.mktoField {
		width: 100% !important;
		margin-bottom: 16px !important;
	}

	.mktoError {
		z-index: 10 !important;
	}

	.mktoLogicalField {
		width: 100% !important;
	}

	.mktoFieldWrap,
	.mktoFormRow .mktoFieldDescriptor {
		width: 100% !important;
		position: relative;
		margin-bottom: 0px;
	}

	.mktoFieldWrap input[type='date'],
	.mktoFieldWrap input[type='email'],
	.mktoFieldWrap input[type='number'],
	.mktoFieldWrap input[type='tel'],
	.mktoFieldWrap input[type='text'],
	.mktoFieldWrap input[type='url'],
	.mktoFieldWrap > input[type='currency'],
	.mktoFieldWrap > label.mktoLabel {
		color: #5d5d69 !important;
		font-size: 1rem !important;
		font-weight: 400 !important;
		line-height: 1.5rem;
	}

	select {
		background-size: auto;
	}

	.mktoRadioList,
	.mktoCheckboxList {
		padding: 0 !important;
	}

	.mktoRadioList > label,
	.mktoCheckboxList > label {
		margin-left: 0 !important;
		margin-top: 0 !important;
		margin-bottom: 12px !important;
		line-height: 1.8 !important;
		font-size: 17px;
		display: block;
		color: #5d5d69;
		position: relative;
		cursor: pointer;
	}

	.form-checkbox + .form-label,
	.mktoCheckboxList .form-checkbox + label,
	.mktoCheckboxList .mktoField + .form-label,
	.mktoCheckboxList .mktoField + label,
	.modal--marketo .mktoCheckboxList .form-checkbox + label,
	.modal--marketo .mktoCheckboxList .mktoField + .form-label,
	.modal--marketo .mktoCheckboxList .mktoField + label {
		font-size: 14px;
		padding-left: 24px;
		padding-bottom: 4px;
	}

	.form-checkbox,
	.form-radio,
	.mktoCheckboxList .mktoField,
	.modal--marketo .mktoCheckboxList .mktoField {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		height: 1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

	.form-checkbox + .form-label:before,
	.form-radio + .form-label:before,
	.mktoCheckboxList .form-checkbox + label:before,
	.mktoCheckboxList .form-radio + label:before,
	.mktoCheckboxList .mktoField + .form-label:before,
	.mktoCheckboxList .mktoField + label:before,
	.modal--marketo .mktoCheckboxList .form-checkbox + label:before,
	.modal--marketo .mktoCheckboxList .form-radio + label:before,
	.modal--marketo .mktoCheckboxList .mktoField + .form-label:before,
	.modal--marketo .mktoCheckboxList .mktoField + label:before {
		content: '';
		background-color: #fff;
		border: 1px solid #d1d7e0;
		box-shadow: none;
		height: 16px;
		width: 16px;
		left: 0;
		top: 4px;
		display: block;
		position: absolute;
		transition: border 50ms ease-out, background-image 50ms ease-out;
	}

	.form-checkbox:focus + .form-label:before,
	.form-radio:focus + .form-label:before,
	.mktoCheckboxList .form-checkbox:focus + label:before,
	.mktoCheckboxList .form-radio:focus + label:before,
	.mktoCheckboxList .mktoField:focus + .form-label:before,
	.mktoCheckboxList .mktoField:focus + label:before,
	.modal--marketo .mktoCheckboxList .form-checkbox:focus + label:before,
	.modal--marketo .mktoCheckboxList .form-radio:focus + label:before,
	.modal--marketo .mktoCheckboxList .mktoField:focus + .form-label:before,
	.modal--marketo .mktoCheckboxList .mktoField:focus + label:before {
		border-color: #6f8dfd;
		box-shadow: 0 0 2px rgba(111, 141, 253, 0.4);
	}

	.form-checkbox:checked + .form-label:before,
	.form-radio:checked + .form-label:before,
	.mktoCheckboxList .form-checkbox:checked + label:before,
	.mktoCheckboxList .form-radio:checked + label:before,
	.mktoCheckboxList .mktoField:checked + .form-label:before,
	.mktoCheckboxList .mktoField:checked + label:before,
	.modal--marketo .mktoCheckboxList .form-checkbox:checked + label:before,
	.modal--marketo .mktoCheckboxList .form-radio:checked + label:before,
	.modal--marketo .mktoCheckboxList .mktoField:checked + .form-label:before,
	.modal--marketo .mktoCheckboxList .mktoField:checked + label:before {
		background-image: url('data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEzcHgiIGhlaWdodD0iOXB4IiB2aWV3Qm94PSIwIDAgMTMgOSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWxuczpza2V0Y2g9Imh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaC9ucyI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIEJldGEgMy4zICgxMTk3MCkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+Y2hlY2sgY29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoIEJldGEuPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNQMiIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9ImNoZWNrLWNvcHkiIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0wLjUwMDAwMCwgLTIuNTk4MDMxKSI+CiAgICAgICAgICAgIDxyZWN0IGlkPSJib3VuZHMiIHNrZXRjaDp0eXBlPSJNU1NoYXBlR3JvdXAiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCI+PC9yZWN0PgogICAgICAgICAgICA8cGF0aCBkPSJNNS4yNDk5OTY0NCw5LjQ1IEwyLjc5OTk5NjQ0LDcgTDEuOTgzMzI5NzcsNy44MTY2NjY2NyBMNS4yNDk5OTY0NCwxMS4wODMzMzMzIEwxMi4yNDk5OTY0LDQuMDgzMzMzMzMgTDExLjQzMzMyOTgsMy4yNjY2NjY2NyBMNS4yNDk5OTY0NCw5LjQ1IFoiIGlkPSJTaGFwZSIgc3Ryb2tlPSIjMDBBQkM5IiBmaWxsPSIjMDBBQkM5IiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 100%;
	}

	.mktoButtonWrap {
		margin-left: 0 !important;
	}

	button.mktoButton {
		border-radius: 5px !important;
		border: 0 !important;
		font-weight: 500;
		line-height: 1;
		transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
	}

	.mktoHtmlText > span {
		color: #ffffff !important;
	}

	&.default {
		.mktoLabel,
		.mktoGutter,
		.mktoOffset {
			display: none !important;
		}

		input[type='text'],
		input[type='url'],
		input[type='email'],
		input[type='tel'],
		input[type='number'],
		input[type='date'],
		textarea.mktoField,
		select.mktoField {
			width: 100% !important;
			box-sizing: border-box;
			height: 56px;
			padding: 8px 24px;
			border: 1px solid #cacaca;
			border-radius: 5px;
			background-color: #fefefe;
			box-shadow: inset 0 1px 2px rgba(76, 75, 88, 0.1);
			font-size: 15px !important;
			font-weight: 400;
			line-height: 1.5;
			color: #4c4b58;
			transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}

		select {
			background-color: #fff;
			background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iNzAwIiBoZWlnaHQ9IjQxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHRpdGxlPkNoZXZyb24gRG93bjwvdGl0bGU+ICAgIDxwYXRoIGQ9Ik02NTAgNjBMMzM5IDMzOSA1MCA1MCIgc3Ryb2tlLXdpZHRoPSIxMDAiIHN0cm9rZT0iIzk3OTc5NyIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
			background-position: 95%;
			background-repeat: no-repeat;
			background-size: 14px;
		}

		.mktoRadioList > label,
		.mktoCheckboxList > label {
			display: block !important;
		}

		.mktoButtonRow {
			padding-top: 8px;
		}
	}

	&.underlined {
		.mktoGutter.mktoHasWidth {
			width: 5px !important;
			display: block !important;
		}

		.mktoFieldWrap.interactive > label.mktoLabel {
			color: #5d5d69 !important;
			font-size: 1rem !important;
			font-weight: 400 !important;
			line-height: 1.5;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			width: 100% !important;
			padding: 4px 12px 4px 0 !important;
			position: absolute;
			top: 30px;
			transition: all 0.2s ease;
		}

		.mktoFieldWrap.interactive.notEmpty > label.mktoLabel {
			bottom: auto;
			font-size: 0.75rem !important;
			top: 10px;
		}

		.mktoFieldWrap .mktoAsterix {
			display: none !important;
		}

		input[type='currency'],
		input[type='date'],
		input[type='email'],
		input[type='number'],
		input[type='tel'],
		input[type='text'],
		input[type='url'],
		input[type='url'] :focus,
		select,
		select:focus {
			background-color: transparent !important;
			border-bottom: 1px solid #121118 !important;
			border: none;
			box-shadow: none !important;
			max-width: none;
			padding-left: 0 !important;
			padding-top: 24px !important;
			position: relative;
			outline: none;
			padding: 2px 4px;
			box-sizing: content-box !important;
		}

		input::placeholder,
		select::placeholder {
			color: transparent;
		}

		.mktoField:not(textarea) {
			height: 20px !important;
		}

		select {
			background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSI2cHgiIHZpZXdCb3g9IjAgMCAxMiA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1NC4xICg3NjQ5MCkgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+U29sdXRpb25zIGFycm93PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkRlc2t0b3AtbWF4LXdpZHRoLSgxNjgwKS1Db3B5LTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzQwLjAwMDAwMCwgLTc4MS4wMDAwMDApIiBmaWxsPSIjMTIxMTE4IiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iU29sdXRpb25zLWFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzQ2LjAwMDAwMCwgNzgxLjAwMDAwMCkgcm90YXRlKC0zMTUuMDAwMDAwKSB0cmFuc2xhdGUoLTEzNDYuMDAwMDAwLCAtNzgxLjAwMDAwMCkgIiBwb2ludHM9IjEzNTAgNzc3IDEzNTAgNzg1IDEzNDIgNzg1Ij48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=) !important;
			background-position-x: 100% !important;
			background-position-y: 75% !important;
			background-repeat: no-repeat !important;
			color: transparent !important;
			appearance: none;
			font-size: 1rem !important;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			cursor: pointer !important;

			& > option {
				color: #5d5d69;
			}
		}

		.mktoFieldWrap.interactive.notEmpty > select {
			color: #5d5d69 !important;
		}

		.mktoButtonRow {
			padding-top: 44px;
		}

	}

	&.dark {
		input[type='currency'],
		input[type='date'],
		input[type='email'],
		input[type='number'],
		input[type='tel'],
		input[type='text'],
		input[type='url'],
		input[type='url'] :focus,
		select,
		select:focus {
			background-color: transparent !important;
			border-color: rgba(255, 255, 255, 0.2) !important;
		}

		input[type='currency'],
		input[type='date'],
		input[type='email'],
		input[type='number'],
		input[type='tel'],
		input[type='text'],
		input[type='url'],
		input[type='url'] :focus {
			color: rgba(255, 255, 255, 0.65) !important;
		}

		select,
		select option {
			&::placeholder {
				color: rgba(255, 255, 255, 0.65) !important;
			}
		}

		.mktoFieldWrap.interactive {

			& > label.mktoLabel {
				
				color: rgba(255, 255, 255, 0.65) !important;
			}

			// & > label.mktoLabel:has(~ select) {
			// 	display: block !important;
			// }

			&.notEmpty {
				.mktoLabel {
					color: #5d5d69 !important;
				}

				input[type='currency'],
				input[type='date'],
				input[type='email'],
				input[type='number'],
				input[type='tel'],
				input[type='text'],
				input[type='url'],
				input[type='url'] :focus,
				select,
				select:focus {
					color: #FFFFFF !important;
				}
			}
		}

		.form-checkbox + .form-label:before,
		.form-radio + .form-label:before,
		.mktoCheckboxList .form-checkbox + label:before,
		.mktoCheckboxList .form-radio + label:before,
		.mktoCheckboxList .mktoField + .form-label:before,
		.mktoCheckboxList .mktoField + label:before,
		.modal--marketo .mktoCheckboxList .form-checkbox + label:before,
		.modal--marketo .mktoCheckboxList .form-radio + label:before,
		.modal--marketo .mktoCheckboxList .mktoField + .form-label:before,
		.modal--marketo .mktoCheckboxList .mktoField + label:before {
			background-color: transparent !important;
		}

		.mktoRadioList > label,
		.mktoCheckboxList > label {
			color: rgba(255, 255, 255, 0.65) !important;
		}
	}

	&.submitBlue {
		button.mktoButton {
			background: #0d52ff !important;
			color: #fff !important;

			&:hover {
				background: #0b42cc !important;
			}
		}
	}

	&.submitBlack {
		button.mktoButton {
			background: #121118 !important;
			color: #fff !important;

			&:hover {
				background: #0d52ff !important;
				color: #fff !important;
			}
		}
	}

	&.submitWhite {
		button.mktoButton {
			background: #fff !important;
			color: #121118 !important;

			&:hover {
				background: #0d52ff !important;
				color: #fff !important;
			}
		}
	}

	&.submitDefault {
		button.mktoButton {
			padding: 12px 24px !important;
			font-size: 14px !important;
		}
	}

	&.submitLarge {
		button.mktoButton {
			padding: 20px 32px !important;
			font-size: 15px !important;
		}
	}

	&.submitLeft {
		.mktoButtonRow {
			text-align: left !important;
		}
	}

	&.submitCenter {
		text-align: center;
	}

	&.submitRight {
		text-align: right;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.radioInput {
	@apply mr-2 w-5 h-5 text-bc-blue border-gray-1100 focus:ring-transparent focus:ring-offset-0;
}

.required::after {
	content: ' *';
	color: #f849a0;
}

.grecaptcha-badge { 
	visibility: hidden; 
}

@keyframes fadeUp {
	0% {
		opacity: 0;
		transform: translateY(0);
	}
	20% {
		opacity: 1;
		transform: translateY(0);
	}
	40% {
		opacity: 1;
		transform: translateY(-7px);
	}
	60% {
		opacity: 1;
		transform: translateY(-7px);
	}
	80% {
		opacity: 1;
		transform: translateY(-15px);
	}
	100% {
		opacity: 0;
		transform: translateY(-15px);
	}
}

.animate-up {
	animation: fadeUp 2s ease-in-out infinite;
}

@keyframes fadeLeft {
	0% {
		opacity: 0;
		transform: translateX(0);
	}
	20% {
		opacity: 1;
		transform: translateX(0);
	}
	40% {
		opacity: 1;
		transform: translateX(-7px);
	}
	60% {
		opacity: 1;
		transform: translateX(-7px);
	}
	80% {
		opacity: 1;
		transform: translateX(-15px);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}

.animate-left {
	animation: fadeLeft 2s ease-in-out infinite;
}


.megamenu-container [class^="css-"]:nth-of-type(1) {
	display: flex !important;
	flex-direction: column !important;
	flex-wrap: nowrap !important;
}

/* Default styles for navigation mobile devices */
.section-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

/* For navigation desktop devices */
@media (min-width: 1024px) {
    .section-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr); 
        gap: 0; 
	}

    .has-featured-section {
        grid-template-columns: repeat(4, 1fr);
        grid-column: span 1; 
    }
}

/* Tablet */

@media (max-width: 1024px) {

	h1 {
	  font-size: 36px;
	  line-height: 1.2;
	  letter-spacing: -0.1px;
	}

	h2 {
	  font-size: 30px;
	  line-height: 1.2;
	  letter-spacing: -0.1px;
	}

	h3 {
	  font-size: 26px;
	  line-height: 1.3;
	  letter-spacing: -0.1px;
	}
  }

  /* Mobile */

  @media (max-width: 768px) {

	h1 {
	  font-size: 32px;
	  line-height: 1.2;
	  letter-spacing: -0.1px;
	}

	h2 {
	  font-size: 28px;
	  line-height: 1.2;
	  letter-spacing: -0.1px;
	}

	h3 {
	  font-size: 26px;
	  line-height: 1.3;
	  letter-spacing: -0.1px;
	}
  }

.drift-wrapper {
	z-index: 999;
	position: fixed;
	display: block;
	right: 12px;
	bottom: 12px;
}
